import VueFlatpickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/l10n/ko.js";
import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

let commFlatpickrMixin = {
  components: {
    "flat-pickr": VueFlatpickr,
  },
  data() {
    return {
      date: null, // 초기 값
      //obj: this, 삭제함
      // https://chmln.github.io/flatpickr/options/
      flatpickrConfigDay: {
        locale: "ko",
        wrap: false,
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        altInput: true,
        isShowSvg: true, // 이전월, 다음월 이동 화살표 출력 여부
        isEnableNumInput: true, // 년도 입력박스 활성화 여부
        isEnableMonthDropdown: false, // 월 선택박스 활성화 여부
        onOpen(selectedDates, dateStr, instance) {
          if (!this.config.isShowSvg) {
            document
              .querySelector(".flatpickr-prev-month svg")
              .setAttribute("style", "display: none;");
            document
              .querySelector(".flatpickr-next-month svg")
              .setAttribute("style", "display: none;");
          }
          if (!this.config.isEnableNumInput) {
            document.querySelector(".numInputWrapper input").setAttribute("disabled", true);
            document
              .querySelector(".numInputWrapper .arrowUp")
              .setAttribute("style", "display: none;");
            document
              .querySelector(".numInputWrapper .arrowDown")
              .setAttribute("style", "display: none;");
          }
          if (!this.config.isEnableMonthDropdown) {
            document
              .querySelector(".flatpickr-monthDropdown-months")
              .setAttribute("disabled", true);
          }
        },
      },
      flatpickrKoConfigDay: {
        locale: "ko",
        wrap: false,
        dateFormat: "Y-m-d",
        altFormat: "Y년 m월 d일",
        altInput: true,
        isShowSvg: true, // 이전월, 다음월 이동 화살표 출력 여부
        isEnableNumInput: true, // 년도 입력박스 활성화 여부
        isEnableMonthDropdown: false, // 월 선택박스 활성화 여부
        onOpen(selectedDates, dateStr, instance) {
          if (!this.config.isShowSvg) {
            document
              .querySelector(".flatpickr-prev-month svg")
              .setAttribute("style", "display: none;");
            document
              .querySelector(".flatpickr-next-month svg")
              .setAttribute("style", "display: none;");
          }
          if (!this.config.isEnableNumInput) {
            document.querySelector(".numInputWrapper input").setAttribute("disabled", true);
            document
              .querySelector(".numInputWrapper .arrowUp")
              .setAttribute("style", "display: none;");
            document
              .querySelector(".numInputWrapper .arrowDown")
              .setAttribute("style", "display: none;");
          }
          if (!this.config.isEnableMonthDropdown) {
            document
              .querySelector(".flatpickr-monthDropdown-months")
              .setAttribute("disabled", true);
          }
        },
      },
      flatpickrConfigTime: {
        locale: "ko",
        wrap: false,
        altInput: false,
        dateFormat: "H:i",
        noCalendar: true,
        enableTime: true,
        minuteIncrement: 30,
        time_24hr: true,
      },
      flatpickrConfigMonth: {
        plugins: [
          new MonthSelectPlugin({
            shorthand: false,
            dateFormat: "Y-m",
            altFormat: "Y-m",
            defaultDate: "today",
          }),
        ],
        locale: "ko",
        isEnableNumInput: true, // 년도 입력박스 활성화 여부
        wrap: false,
        onOpen(selectedDates, dateStr, instance) {
          let today = new Date();
          let currentMonth = today.getMonth();
          let currentMonthElem = document.querySelector(".flatpickr-monthSelect-months").children[
            currentMonth
          ];
          currentMonthElem.classList.add("current_month");
        },
        onChange(selectedDates, dateStr, instance) {
          let oldDate = instance.config.defaultDate;
          let newDate = dateStr;
          let oldYear = "";
          if (oldDate === undefined) {
            oldYear = instance.currentYear + "";
          } else {
            oldYear = oldDate.substr(0, 4);
          }
          if (oldYear === newDate.substr(0, 4)) {
            instance.close();
          } else {
            instance.config.defaultDate = dateStr;
          }
        },
      },
    };
  },
};

export default commFlatpickrMixin;
